// 
// coming Soon 
// 

.counter-number {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: $dark;
    display: flex;
    span {
        font-size: 16px;
        font-weight: 400;
        display: block;
        padding-top: 5px;
        color: $dark;
    }
}

.coming-box {
    width: 25%;
}