// 
// Vector Map
// 

.jvm-tooltip {
    border-radius: 3px;
    background-color: $primary;
    font-family: $font-family-sans-serif;
    box-shadow: none;
    padding: 5px 9px
}

.jvm-container text {
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    box-shadow: none;
    fill: #287F71;
}

.jvm-zoom-btn {
    background-color: $dark;
}


// Google Map CSS
.google-maps {
    height: 420px;
}
