// 
// Calendar.scss
//
/* ==============
  Calendar
===================*/

.fc td,
.fc th {
    border: $table-border-width solid $table-border-color;
}

.fc {
    .fc-toolbar {
        h2 {
            font-size: 16px;
            line-height: 30px;
            font-weight: 600;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {
            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            > * > * {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }
    }
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {
    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: $card-bg;
    border-color: var(--#{$prefix}border-color);
    color: $gray-700;
    text-transform: capitalize !important;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;

    &.fc-today-button.fc-button-primary {
        background-color: $primary-bg-subtle !important;
        color: $primary !important;
    }
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    padding: 4px;
    text-align: center;
    border-left: 4px solid;
}

.fc-event-dot {
    background-color: $primary;
}

.fc-daygrid-event-dot {
    // border: calc(var(--fc-daygrid-event-dot-width) / 2) solid var(--#{$prefix}dark);
    border: none;
}

.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
    }
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content {
        color: var(--#{$prefix}light);
    }
}

.fc .fc-button-primary {
    background-color: var(--#{$prefix}primary) !important;
    border-color: var(--#{$prefix}primary) !important;
    box-shadow: none !important;
}

.fc-scrollgrid {
    border-color: var(--#{$prefix}border-color) !important;
}

.fc-theme-standard .fc-list-day-cushion {
    background-color: var(--#{$prefix}tertiary-bg) !important;
}

.fc-theme-standard .fc-list {
    border: var(--#{$prefix}border-color) !important;
}

.fc-event{
    justify-content: center;
    .fc-event-title{
        flex-grow: 0 !important;
        font-weight: 500;
    }
}

.fc .fc-daygrid-day.fc-day-today{
    background-color: $info-bg-subtle !important;
}

// RTL
[dir="rtl"] {
    .fc-header-toolbar {
        direction: ltr !important;
    }

    .fc-toolbar > * > :not(:first-child) {
        margin-left: 0.75em;
    }
}

.fc-toolbar {
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}


.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
    color: $gray-700; 
}

// overriding button sizes
.fc-toolbar {
    .btn {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
    }
}

// Event color add in Javascript
.event-primary {
    background-color: $primary-bg-subtle !important;
    color: $dark !important;
}
.event-secondary {
    background-color: $secondary-bg-subtle !important;
    color: $secondary !important;
}
.event-danger {
    background-color: $danger-bg-subtle !important;
    color: $danger !important;
}
.event-warning {
    background-color: $warning-bg-subtle !important;
    color: $warning !important;
}
.event-info {
    background-color: $info-bg-subtle !important;
    color: $info !important;
}
.event-dark {
    background-color: $dark-bg-subtle !important;
    color: $dark !important;
}