// 
// _custom-icons.scss
// 

.icon-demo .col-xl-3.col-lg-4.col-sm-6{
    cursor: pointer;
    line-height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
        color: $primary;
    }
}
.icon-demo .icon-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: 0px;
    background-color: $white;
    align-items: center;
}
.icon-demo .material-design-icon i {
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    display: inline-block;
}