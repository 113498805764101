// 
// popover.scss
//

.custom-popover {
    --bs-popover-max-width: 200px;
    --bs-popover-border-color: var(--bs-primary);
    --bs-popover-header-bg: var(--bs-primary);
    --bs-popover-header-color: var(--bs-white);
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: .5rem;
}

.popover-header {
    margin-top: 0;
}