// 
// Gallery
// 

.gallery-container.gallery-grid {
    transition: all .5s ease;
    &:hover {
        box-shadow: 0 0 3px rgba(60,72,88,.15);
    }
    &:hover img {
        transform: scale(1.3) rotate(3deg);
    }
    img {
        transition: all .5s ease;
    }
}