
// 
// widgets.scss
//

// widget-box-2
.widget-box-2 {
    .widget-detail-2 {
      .badge {
        padding: 5px 10px;
      }
    }
}

.widget-box {
    .widget-icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icons {
            font-size: 20px;
        }
    }
}

// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        border-bottom: 1px solid #{rgba(map-get($grays, "300"),0.5)};
        overflow: hidden;
        padding: 0.75rem 0;
        position: relative;
        &:last-of-type {
            border-bottom: none;
        }
        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;
            img {
                width: 40px;
            }
        }
        .inbox-item-author {
            display: block;
            margin-bottom: 3px;
        }
        .inbox-item-text {
            color: $text-muted;
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }
        .inbox-item-date {
            color: #{map-get($grays, "600")};
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}

/* Chat widget */
.conversation-list {
    list-style: none;
    padding: 0 15px;
    li {
        margin-bottom: 20px;

        .conversation-actions {
            float: right;
            display: none;
        }

        &:hover {
            .conversation-actions {
                display: block;
            }
        }
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        // background: $chat-secondary-user-bg;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            // font-weight: $label-font-weight;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            left: -10px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            // border-top-color: $chat-secondary-user-bg;
            border-width: 6px;
            margin-right: -1px;
            // border-right-color: $chat-secondary-user-bg;
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }
    .odd {
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70% !important;
        }
        .ctext-wrap {
            // background-color: $chat-primary-user-bg;
            &:after {
                border-color: transparent;
                // border-left-color: $chat-primary-user-bg;
                // border-top-color: $chat-primary-user-bg;
                right: -10px !important;
                left: auto;
            }
        }

        .conversation-actions {
            float: left;
        }
    }
}

.card-table {
    background-color: $white;
}

.debit-card {
    background-image: url(../images/svg/card-bg.svg);
    background-size: cover;
    background-position: center center;
}

.apexcharts-title-text {
    font-family: $font-family-base !important;
    font-weight: 500 !important;
}

.apexcharts-legend-text,
.apexcharts-xaxis text,
.apexcharts-yaxis text,
.apexcharts-data-labels {
    font-family: $font-family-base !important;
}

.apexcharts-legend-marker {
    margin-right: 10px !important;
    height: 7px !important;
    width: 20px !important;
}

.widget-icons-sections {
    padding: 4px 8px;
    .widgets-icons {
        width: 16px;
    }
}

// Product List
.product-list {
    display: flex;
    align-items: flex-start;
    padding-left: 0.5rem;
}

.product-body {
    flex: 1;
}

// Widget First Sections
.border-dashed {
    --#{$prefix}border-style: dashed;
    // border: 1px dashed;
}

.dot-primary {
    color: $blue;
}

.bg-tag {
    background-color: $blue-100;
}

.upcoming-events-list {
    li:first-child::before{
        content: "";
        background-color: rgba($primary, 0.5);
    }
    li:nth-child(2)::before{
        content: "";
        background-color: rgba($warning, 0.5);
    }
    li:nth-child(3)::before{
        content: "";
        background-color: rgba($secondary, 0.5);
    }
    li:nth-child(4)::before{
        content: "";
        background-color: rgba($danger, 0.5);
    }
}

.upcoming-events-list li:before {
    content: "";
    position: absolute;
    height: 100%;
    width: .25rem;
    border-radius: .5rem;
}
.upcoming-events-list li {
    position: relative;
    margin-block-end: 17px;
}

// Dashboard widget scss
.ballot-point {
    width: 5px;
    height: 5px;
    display: block;
    border-radius: 50%;
    margin-inline-end: 10px;
    margin-block-start: 6px;
}

.widget-size {
    width: 2rem !important;
    height: 2rem !important;
    line-height: 2rem;
    font-size: 0.8rem;
}