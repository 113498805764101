// 
// dropdown.scss
//

.dropdown-menu {
    box-shadow: $box-shadow-sm;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    i {
        display: inline-block;
    }
}

.dropdown-lg {
    min-width: 22rem;
    padding: 0px;
    box-shadow: $box-shadow-sm;
}

/* DropDownSlide */ 
@keyframes DropDownSlide {
    100% {
        margin-top: -1px
    }

    0% {
        margin-top: 8px
    }
}